<template>
  <v-card class="pa-5 mt-3">
    <v-toolbar flat>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('search')"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn
        :v-if="$store.getters.isInRole(117)"
        color="primary"
        dark
        class="mb-2"
        @click="dialog = true"
      >
        {{ $t("add") }}
        <v-icon> mdi-plus </v-icon>
      </v-btn>
    </v-toolbar>

    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card class="px-5">
        <add-patient-history
          :dialog="true"
          :patientGuid="patientGuid"
          :editPatientHistoryModel="editPatientHistoryModel"
          :onClose="onClose"
          @cancelEdit="cancelEdit"
          @savePatientHistory="refreshTable"
        />
      </v-card>
    </v-dialog>

    <data-table-component
      :headers="headers"
      :items="history"
      :search="search"
      :loading="loading"
    />

    <confirm-dialog
      :openDialog="dialogDelete"
      :onClicked="deleteItemConfirm"
      :onClose="closeDelete"
    ></confirm-dialog>
  </v-card>
</template>

<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import AddPatientHistory from "@/views/PatientHistory/AddPatientHistory.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
export default {
  components: { DataTableComponent, AddPatientHistory, ConfirmDialog },
  props: ["patientGuid"],

  data() {
    return {
      history: [],
      search: "",
      loading: false,
      dialog: false,
      dialogDelete: false,
      editPatientHistoryModel: {},
      editedItem: {},
    };
  },
  computed: {
    headers() {
      var list = [
        {
          text: this.$t("patientHistory.initialDiagnosis"),
          value: "initialDiagnosis",
        },
        {
          text: this.$t("patientHistory.finalDiagnosis"),
          value: "finalDiagnosis",
        },
        {
          text: this.$t("patientHistory.chiefComplaintAndDuration"),
          value: "chiefComplaintAndDuration",
        },
        {
          text: this.$t("patientHistory.drugsHistoryAndDrugsAllergy"),
          value: "drugsHistoryAndDrugsAllergy",
        },
        {
          text: this.$t("patientHistory.historyOfPresentIllness"),
          value: "historyOfPresentIllness",
        },
        {
          text: this.$t("patientHistory.medicalAndSurgicalHistory"),
          value: "medicalAndSurgicalHistory",
        },
        { text: this.$t("notes"), value: "note" },
        {
          text: this.$t("date"),
          value: "dateReg",
          type: "date",
          format: "yyyy-MM-DD hh:mm a ",
        },

        // {
        //   text: this.$t("tests.testPrice"),
        //   value: "testPrice",
        //   type: "number",
        //   format: "0,0",
        // },
      ];

      var actions = {
        text: "",
        value: "actions",
        templates: [],
      };

      if (this.$store.getters.isInRole(118)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-pencil",
          text: this.$t("edit"),
          click: this.editItem,
        });
      }
      if (this.$store.getters.isInRole(119)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-delete",
          text: this.$t("delete"),
          click: this.deleteItem,
          color: "red darken-2",
        });
      }
      list.push(actions);
      return list;
    },
  },

  created() {
    this.loading = true;
    this.refreshTable();
  },

  methods: {
    editItem(item) {
      // console.log("asdasdas");

      this.editedIndex = this.history.indexOf(item);
      setTimeout(() => {
        this.editPatientHistoryModel = Object.assign({}, item);
        this.dialog = true;
      }, 30);
    },

    deleteItem(item) {
      this.editedIndex = this.history.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$axios
        .delete("PatientHistory/Delete?guid=" + this.editedItem.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }
          this.refreshTable();
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });

      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, {});
      });
    },

    cancelEdit() {
      this.editPatientHistoryModel = null;
    },

    refreshTable() {
      this.$axios
        .get("PatientHistory/GetPatientHistory?patientGuid=" + this.patientGuid)
        .then((response) => {
          this.history = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onClose() {
      this.dialog = false;
      this.editPatientHistoryModel = null;
    },
  },
};
</script>

<style></style>
