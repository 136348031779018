<template>
  <div>
    <DxFileManager
      :rtlEnabled="$vuetify.rtl"
      :file-system-provider="remoteProvider"
      :allowed-file-extensions="allowedFileExtensions"
      :on-selected-file-opened="displayImagePopup"
      :on-context-menu-item-click="onItemClick"
      ref="dxFileManager"
    >
      <dx-permissions
        :create="true"
        :copy="true"
        :move="true"
        :delete="true"
        :rename="true"
        :upload="true"
        :download="true"
      />
      <DxUpload :chunk-size="50000" />
      <DxToolbar>
        <DxItem name="showNavPane" :visible="true" />
        <DxItem name="separator" />
        <DxItem name="create" />
        <DxItem name="upload" />

        <DxItem name="refresh" />
        <DxItem name="separator" location="after" />
        <DxItem name="switchView" />

        <DxFileSelectionItem name="rename" />
        <DxFileSelectionItem name="copy" />
        <DxFileSelectionItem name="move" />
        <DxFileSelectionItem name="separator" />
        <DxFileSelectionItem name="delete" :text="$t('delete')" />
        <DxFileSelectionItem name="separator" />
        <DxFileSelectionItem
          widget="dxButton"
          :options="{
            text: $t('preview'),
            icon: 'search',
            onClick: displayImagePopup,
          }"
        />
        <DxFileSelectionItem
          widget="dxButton"
          :options="{
            text: $t('download'),
            icon: 'download',
            onClick: onToolbarDownloadClick,
          }"
        />

        <DxFileSelectionItem name="refresh" />
        <DxFileSelectionItem name="clearSelection" />
      </DxToolbar>
      <DxContextMenu>
        <DxItem name="create" />
        <DxItem name="copy" />
        <DxItem name="move" />
        <DxItem name="rename" :begin-group="true" />
        <DxItem name="delete" :text="$t('delete')" />
        <DxFileSelectionItem
          :selectable="true"
          :text="$t('download')"
          icon="download"
          :options="{ download: true }"
        />
      </DxContextMenu>
    </DxFileManager>

    <!-- <v-dialog v-model="popupVisible" max-width="800">
      <v-card class="rounded-b-0">
        <v-card-title> </v-card-title>
        <v-card-text>
          <v-carousel :show-arrows="true">
            <v-carousel-item
              v-for="(item, i) in selectedItems"
              :key="i"
              reverse-transition="fade-transition"
              transition="fade-transition"
            >
              <template v-slot:default>
                <DxScrollView height="100%" width="100%">
                  <img :src="item.dataItem.url" contain max-width="100%" />
                </DxScrollView>
              </template>
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
      </v-card>
    </v-dialog>
    <DxPopup />-->
    <DxScrollView />
    <DxPopup
      v-if="selectedItems && selectedItems[carousel]"
      :visible="popupVisible"
      :title="selectedItems[carousel].name"
      @hiding="onHiding"
      max-height="800"
      max-width="800"
      class="photo-popup-content"
    >
      <template #content>
        <DxGallery
          id="gallery"
          v-model="carousel"
          :data-source="selectedItems"
          :loop="true"
          :show-nav-buttons="false"
          :show-indicator="true"
          height="100%"
          width="100%"
        >
          <template #item="{ data }">
            <div class="content">
              <DxScrollView height="100%" width="100%">
                <img
                  :src="data.dataItem.url"
                  class="photo-popup-image"
                  width="100%"
                />
              </DxScrollView>
            </div>
          </template>
        </DxGallery>
        <!-- <v-carousel :show-arrows="false" v-model="carousel">
          <v-carousel-item
            v-for="(item, i) in selectedItems"
            :key="i"
            reverse-transition="fade-transition"
            transition="fade-transition"
          >
            <template v-slot:default>
              <DxScrollView height="100%" width="100%">
                <img
                  :src="item.dataItem.url"
                  width="100%"
                  class="photo-popup-image"
                />
              </DxScrollView>
            </template>
          </v-carousel-item>
        </v-carousel> -->
      </template>
    </DxPopup>
  </div>
</template>

<script>
import {
  DxFileManager,
  DxPermissions,
  DxUpload,
  DxToolbar,
  DxContextMenu,
  DxItem,
  DxFileSelectionItem,
} from "devextreme-vue/file-manager";
import { DxPopup } from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";
import RemoteFileSystemProvider from "devextreme/file_management/remote_provider";

const allowedFileExtensions = [
  ".jpg",
  ".jpeg",
  ".jpe",
  ".gif",
  ".png",
  ".bmp",
  ".zip",
  ".rar",
  ".doc",
  ".docx",
  ".xls",
  ".xlsx",
  ".ppt",
  ".pptx",
  ".txt",
  ".pdf",
  ".wav",
  ".mp3",
  ".aac",
  ".mp4",
  ".wmv",
  ".flv",
  ".avi",
  ".webm",
];

import arMessages from "../locales/devextreme-ar.json";
import { locale, loadMessages } from "devextreme/localization";
// import themes from "devextreme/ui/themes";

import "devextreme-vue/button";
import "devextreme/ui/button";
// import darkcss from "devextreme/dist/css/dx.dark.css";
// import lightcss from "devextreme/dist/css/dx.light.css";

import DxGallery from "devextreme-vue/gallery";

export default {
  props: ["guid"],
  components: {
    DxFileManager,
    DxPermissions,
    DxUpload,
    DxPopup,
    DxScrollView,
    DxToolbar,
    DxContextMenu,
    DxItem,
    DxFileSelectionItem,
    DxGallery,
  },
  data() {
    return {
      allowedFileExtensions,
      popupVisible: false,
      selectedItem: [],
      carousel: 0,
      // themes,
      // lightcss,
      // darkcss,
    };
  },
  created() {
    loadMessages(arMessages);
  },
  computed: {
    notEmptyGuid() {
      return this.guid != null && this.guid != undefined && this.guid != "";
    },

    remoteProvider() {
      var token = `Bearer ${localStorage.getItem("token")}`;
      return new RemoteFileSystemProvider({
        endpointUrl:
          process.env.VUE_APP_CLIENT_API_URL +
          "file-manager-file-system?guid=" +
          this.guid,
        beforeAjaxSend: function ({ headers, formData, xhrFields }) {
          headers.authorization = token;
          formData.dataValue = this.guid;
          xhrFields.withCredentials = true;
        },
      });
    },

    localLang() {
      var la = this.$vuetify.rtl ? "ar" : "en-US";
      locale(la);
      return la;
    },

    dark() {
      var dark = this.$vuetify.theme.isDark;
      if (dark) {
        //require("devextreme/dist/css/dx.dark.css");
        // this.themes.current("generic.dark");
        // this.themes.resetTheme();
      } else {
        // require("devextreme/dist/css/dx.light.css");
        // this.themes.current("generic.light");
        // this.themes.resetTheme();
      }
      return dark;
    },

    selectedItems() {
      return this.selectedItem.filter((f) => !f.isDirectory);
    },
  },
  watch: {
    localLang(la) {
      locale(la);
    },
    dark(da) {
      //window.location.reload();
      if (da) {
        //require("devextreme/dist/css/dx.dark.css");
      } else {
        //window.location.reload();
      }
    },
  },
  methods: {
    onHiding() {
      this.popupVisible = false;
    },
    displayImagePopup() {
      this.selectedItem = this.$refs.dxFileManager.instance.getSelectedItems();
      this.popupVisible = true;
    },
    onToolbarDownloadClick() {
      this.selectedItem = this.$refs.dxFileManager.instance.getSelectedItems();
      for (let index = 0; index < this.selectedItem.length; index++) {
        const element = this.selectedItem[index];
        this.downloadItem(element.path);
      }
    },
    onItemClick(e) {
      if (e.itemData.options && e.itemData.options.download) {
        if (e.fileSystemItem && !e.fileSystemItem.isDirectory) {
          this.downloadItem(e.fileSystemItem.path);
        } else {
          this.$toast.error(this.$t("error.selectFileFirst"));
        }
      }
    },
    downloadItem(path) {
      var url =
        "file-manager-download-file?guid=" + this.guid + "&path=" + path;

      let postConfig = {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
        responseType: "blob",
      };
      this.$axios
        .get(url, postConfig)
        .then((response) => {
          if (response.status == 200) {
            var file = new Blob([response.data], {
              type: response.headers["content-type"],
            });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          } else {
            this.$toast.error("Some thing went wrong");
          }
        })
        .catch((e) => {
          this.$toast.error("Some thing went wrong");
          console.log(e);
        });
    },
  },
};
</script>

<style scoped></style>
