var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('DxFileManager',{ref:"dxFileManager",attrs:{"rtlEnabled":_vm.$vuetify.rtl,"file-system-provider":_vm.remoteProvider,"allowed-file-extensions":_vm.allowedFileExtensions,"on-selected-file-opened":_vm.displayImagePopup,"on-context-menu-item-click":_vm.onItemClick}},[_c('dx-permissions',{attrs:{"create":true,"copy":true,"move":true,"delete":true,"rename":true,"upload":true,"download":true}}),_c('DxUpload',{attrs:{"chunk-size":50000}}),_c('DxToolbar',[_c('DxItem',{attrs:{"name":"showNavPane","visible":true}}),_c('DxItem',{attrs:{"name":"separator"}}),_c('DxItem',{attrs:{"name":"create"}}),_c('DxItem',{attrs:{"name":"upload"}}),_c('DxItem',{attrs:{"name":"refresh"}}),_c('DxItem',{attrs:{"name":"separator","location":"after"}}),_c('DxItem',{attrs:{"name":"switchView"}}),_c('DxFileSelectionItem',{attrs:{"name":"rename"}}),_c('DxFileSelectionItem',{attrs:{"name":"copy"}}),_c('DxFileSelectionItem',{attrs:{"name":"move"}}),_c('DxFileSelectionItem',{attrs:{"name":"separator"}}),_c('DxFileSelectionItem',{attrs:{"name":"delete","text":_vm.$t('delete')}}),_c('DxFileSelectionItem',{attrs:{"name":"separator"}}),_c('DxFileSelectionItem',{attrs:{"widget":"dxButton","options":{
          text: _vm.$t('preview'),
          icon: 'search',
          onClick: _vm.displayImagePopup,
        }}}),_c('DxFileSelectionItem',{attrs:{"widget":"dxButton","options":{
          text: _vm.$t('download'),
          icon: 'download',
          onClick: _vm.onToolbarDownloadClick,
        }}}),_c('DxFileSelectionItem',{attrs:{"name":"refresh"}}),_c('DxFileSelectionItem',{attrs:{"name":"clearSelection"}})],1),_c('DxContextMenu',[_c('DxItem',{attrs:{"name":"create"}}),_c('DxItem',{attrs:{"name":"copy"}}),_c('DxItem',{attrs:{"name":"move"}}),_c('DxItem',{attrs:{"name":"rename","begin-group":true}}),_c('DxItem',{attrs:{"name":"delete","text":_vm.$t('delete')}}),_c('DxFileSelectionItem',{attrs:{"selectable":true,"text":_vm.$t('download'),"icon":"download","options":{ download: true }}})],1)],1),_c('DxScrollView'),(_vm.selectedItems && _vm.selectedItems[_vm.carousel])?_c('DxPopup',{staticClass:"photo-popup-content",attrs:{"visible":_vm.popupVisible,"title":_vm.selectedItems[_vm.carousel].name,"max-height":"800","max-width":"800"},on:{"hiding":_vm.onHiding},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('DxGallery',{attrs:{"id":"gallery","data-source":_vm.selectedItems,"loop":true,"show-nav-buttons":false,"show-indicator":true,"height":"100%","width":"100%"},scopedSlots:_vm._u([{key:"item",fn:function({ data }){return [_c('div',{staticClass:"content"},[_c('DxScrollView',{attrs:{"height":"100%","width":"100%"}},[_c('img',{staticClass:"photo-popup-image",attrs:{"src":data.dataItem.url,"width":"100%"}})])],1)]}}],null,false,775079399),model:{value:(_vm.carousel),callback:function ($$v) {_vm.carousel=$$v},expression:"carousel"}})]},proxy:true}],null,false,1171890965)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }